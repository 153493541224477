const links = {
  eli: "https://api.whatsapp.com/send/?phone=56965677552&text=Quiero+confirmar+mi+asistencia+a+tu+matrimonio",
  nico: "https://api.whatsapp.com/send/?phone=56989327702&text=Quiero+confirmar+mi+asistencia+a+tu+matrimonio",
};

export default function ConfirmLink({ name }) {
  return (
    <>
      <a
        className="btn btn-primary btn-lg d-none d-sm-block"
        href={links[name.toLocaleLowerCase()]}
      >
        Confirmar con {name}
      </a>
      <a
        className="btn btn-primary btn-sm d-sm-none"
        href={links[name.toLocaleLowerCase()]}
      >
        Confirmar con {name}
      </a>
    </>
  );
}
