import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Faq from "./screens/Faq";
import Gift from "./screens/Gift";
import Home from "./screens/Home";
import Location from "./screens/Location";
import Thanks from "./screens/Thanks";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="como-llegar" element={<Location />} />
        <Route path="que-regalo" element={<Gift />} />
        <Route path="dudas" element={<Faq />} />
        <Route path="gracias" element={<Thanks />} />
      </Route>
    </Routes>
  );
}

export default App;
