/* eslint-disable jsx-a11y/anchor-is-valid */

export default function OpenModalLink({ toggle }) {
  return (
    <a
      href="#"
      style={{ textDecoration: "none" }}
      onClick={(event) => {
        event.preventDefault();
        toggle();
      }}
    >
      aquí
    </a>
  );
}
