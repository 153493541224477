export default function Location() {
  return (
    <main className="secondary-page fs-5">
      <h1 className="title text-center">¿Cómo llegar?</h1>
      <p className="text-center">Centro de eventos Las Terrazas de La Reina.</p>
      <p className="text-center">
        Av. Alcalde Fernando Castillo Velasco 11095, La Reina, Región
        Metropolitana (Al interior del Parque Mahuida).
      </p>
      <h2 className="text-center">Indicaciones</h2>
      <p className="text-center">
        El centro de eventos se encuentra adentro del parque, tienes que acercarte a la entrada y decirle al guardia que vas a un matrimonio en Las Terrazas de La Reina.
      </p>
      <p className="text-center">
        Si vienes en Uber, te recomendamos que pidas que te dejen al lado de la entrada del centro de eventos. De esa manera, no tienen que caminar.
      </p>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3328.79185521388!2d-70.51948274874779!3d-33.45472898067845!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cde4f6f7e373%3A0x845565ad3698f40f!2sTerrazas%20de%20La%20Reina!5e0!3m2!1ses-419!2scl!4v1670780482726!5m2!1ses-419!2scl"
        width="100%"
        height="450"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="mapa en GoogleMaps"
      ></iframe>
      <p className="text-center">
        <a
          className="btn btn-primary mt-3"
          href="https://goo.gl/maps/rNAfkozkXZaQrYAt6"
          target="_blank"
          rel="noopener noreferrer"
        >
          Ver en Google Maps
        </a>
      </p>
      <p className="text-center">
        <a
          className="btn btn-primary"
          href="https://ul.waze.com/ul?preview_venue_id=189728185.1897150783.3877253&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location"
          target="_blank"
          rel="noopener noreferrer"
        >
          Ver en Waze
        </a>
      </p>
      <blockquote>
        "Debo decirle que ha embrujado usted mi cuerpo y mi alma y que la amo,
        la amo y la amo y que ya nada podrá separarme de usted" - Mr. Darcy
      </blockquote>
    </main>
  );
}
