import { useState } from "react";
import { Card, CardBody } from "reactstrap";
import "../App.css";
import portada from "../images/portada.png";
import calendarIcon from "../images/calendario.svg";
import watchIcon from "../images/reloj.svg";
import separator from "../images/separator-2.svg";
import ConfirmModal from "../components/ConfirmModal";
import OpenModalLink from "../components/OpenModalLink";

function Home() {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal((m) => !m);

  return (
    <div className="App">
      <main>
        <div className="container">
          <h2 className="names">Eli y Nico</h2>
          <img className="photo" src={portada} alt="" />
          <p className="text-center">
            Porque eres importante para nosotros y queremos compartir este
            momento especial contigo, te invitamos a la realización de nuestro
            matrimonio.
          </p>
          <p className="text-center">
            La ceremonia y fiesta se realizarán en el centro de eventos Terrazas
            de La Reina, el día:
          </p>
          <div style={{ maxWidth: "375px", margin: "0 auto" }}>
            <div className="d-flex text-center justify-content-center">
              <div className="col">
                <img
                  style={{ width: "3rem", height: "3rem" }}
                  src={calendarIcon}
                  alt=""
                />
                <p style={{ fontSize: "1.1rem" }}>
                  SÁBADO 25
                  <br />
                  FEBRERO 2023
                </p>
              </div>
              <img className="separator" src={separator} alt="" />
              <div className="col">
                <img
                  style={{ width: "3rem", height: "3rem" }}
                  src={watchIcon}
                  alt=""
                />
                <p style={{ fontSize: "1.2rem" }}>19:00 HRS</p>
              </div>
            </div>
          </div>
          <p className="mt-5 mb-4 d-none d-sm-block fs-5">
            Confirma tu asistencia <OpenModalLink toggle={toggle} />.
          </p>
          <Card className="mt-4 mb-3 d-sm-none">
            <CardBody>
              <button className="btn btn-primary btn-lg" onClick={toggle}>
                Confirmar asistencia
              </button>
            </CardBody>
          </Card>
          <blockquote>
            “Preferiría compartir una vida contigo que enfrentar todas las
            edades de este mundo sola” - Arwen
          </blockquote>
        </div>
      </main>
      <ConfirmModal isOpen={modal} toggle={toggle} />
    </div>
  );
}

export default Home;
