import React, { useState } from "react";
import chewy from "../images/chewy-thanks.jpg";
import OpenModalLink from "../components/OpenModalLink";
import ConfirmModal from "../components/ConfirmModal";

export default function Thanks() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggle = () => setIsModalOpen((open) => !open);

  return (
    <main className="secondary-page fs-5 text-center">
      <img
        className="img-thumbnail"
        style={{ maxWidth: "300px" }}
        src={chewy}
        alt="Chewy dando las gracias"
      />
      <h1 className="title mb-3 mt-3">¡Muchas gracias por tu regalo!</h1>
      <p>
        No importa la cantidad, intentaremos darle un buen uso al dinero,
        probablemente contribuya a algún viaje, cervezas, juegos de Play Station
        5, libros, cursos o al ahorro para un nuevo hogar.
      </p>
      <p>
        Ahora lo más importante es que nos acompañes en el gran día.
        <br />
        Si aún no has confirmado tu asistencia, recuerda hacerlo{" "}
        <OpenModalLink toggle={toggle} />.
      </p>
      <blockquote>
        “Mi corazón ha sido tuyo desde la primera vez que te vi, y has sostenido
        mi alma y mi cuerpo entre tus dos manos aquí, y los has mantenido a
        salvo” - Jamie Fraser
      </blockquote>
      <ConfirmModal toggle={toggle} isOpen={isModalOpen} />
    </main>
  );
}
