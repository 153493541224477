import { Modal, ModalBody, ModalHeader } from "reactstrap";
import eli from "../images/eli.png";
import ConfirmLink from "./ConfirmLink";
import nico from "../images/nico.png";

export default function ConfirmModal({ isOpen, toggle }) {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Confirmar asistencia</ModalHeader>
      <ModalBody>
        <p className="fs-5">Puedes confirmar con nosotros por WhatsApp:</p>
        <div className="row">
          <div className="col text-center">
            <img className="rounded-1" src={eli} alt="" />
            <div className="d-grid gap-2 mt-3">
              <ConfirmLink name="Eli" />
            </div>
          </div>
          <div className="col text-center ps-0">
            <img className="rounded-1" src={nico} alt="" />
            <div className="d-grid gap-2 mt-3">
              <ConfirmLink name="Nico" />
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
