import { NavLink } from "react-router-dom";
import classes from "./FooterNav.module.css";
import MapIcon from "../images/map.svg";
import MapIconPink from "../images/map-pink.svg";
import GiftIcon from "../images/gift.svg";
import GiftIconPink from "../images/gift-pink.svg";
import FaqIcon from "../images/question.svg";
import FaqIconPink from "../images/question-pink.svg";
import HomeIcon from "../images/home.svg";
import HomeIconPink from "../images/home-pink.svg";

export default function FooterNav() {
  return (
    <nav className={classes["footer-nav"]}>
      <ul>
        <li>
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? classes.active : undefined
            }
          >
            <img className={classes["footer-nav-icon"]} src={HomeIcon} alt="" />
            <img
              className={classes["footer-nav-icon"]}
              src={HomeIconPink}
              alt=""
            />
            <span className={classes["footer-nav-label"]}>Inicio</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="como-llegar"
            className={({ isActive }) =>
              isActive ? classes.active : undefined
            }
          >
            <img className={classes["footer-nav-icon"]} src={MapIcon} alt="" />
            <img
              className={classes["footer-nav-icon"]}
              src={MapIconPink}
              alt=""
            />
            <span className={classes["footer-nav-label"]}>Ubicación</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="que-regalo"
            className={({ isActive }) =>
              isActive ? classes.active : undefined
            }
          >
            <img className={classes["footer-nav-icon"]} src={GiftIcon} alt="" />
            <img
              className={classes["footer-nav-icon"]}
              src={GiftIconPink}
              alt=""
            />
            <span className={classes["footer-nav-label"]}>Regalo</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="dudas"
            className={({ isActive }) =>
              isActive ? classes.active : undefined
            }
          >
            <img className={classes["footer-nav-icon"]} src={FaqIcon} alt="" />
            <img
              className={classes["footer-nav-icon"]}
              src={FaqIconPink}
              alt=""
            />
            <span className={classes["footer-nav-label"]}>Dudas</span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}
