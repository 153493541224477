import React, { useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
const faqs = require('../data/faqs.json');

export default function Faq() {
  const [open, setOpen] = useState("0");
  const toggle = (id) => {
    if (open === id) {
      setOpen(null);
    } else {
      setOpen(id);
    }
  };

  return (
    <main className="secondary-page fs-5">
      <h1 className="title text-center mb-3">Preguntas Frecuentes</h1>
      <Accordion open={open} toggle={toggle}>
        {faqs.map((faq, index) => {
          return (
          <AccordionItem>
            <AccordionHeader targetId={index}>
              {faq.question}
            </AccordionHeader>
            <AccordionBody accordionId={index}>
              {faq.question === "¿Cuál es el cronograma?" ? (
                <ul>
                   <li>19:00 - Llegada de invitados e invitadas</li>
                   <li>19:30 - Ceremonia</li>
                   <li>20:00 - Cóctel</li>
                   <li>21:45 - Cena</li>
                   <li>23:30 - Inicio de la Fiesta!</li>
                   <li>05:00 - Término</li>
                 </ul>
              ) : (
                faq.answer.map(((paragraph) => {
                  return (
                    <p>
                      {paragraph}
                    </p>
                  )
                }))
              )}
            </AccordionBody>
          </AccordionItem>
          )
        })}
      </Accordion>
      <blockquote>
        "Eres mío, al igual que yo soy tuya. Si tenemos que morir, moriremos.
        Todos los hombres mueren, Jon Nieve. Pero antes vamos a vivir" - Ygritte
      </blockquote>
    </main>
  );
}
