import { Outlet } from "react-router-dom";
import FooterNav from "./FooterNav";
import classes from "./Layout.module.css";

export default function Layout() {
  return (
    <div className={classes.layout}>
      <Outlet />
      <FooterNav />
    </div>
  );
}
