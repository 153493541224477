import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Button,
  UncontrolledCarousel,
  ModalHeader,
  ModalBody,
  Modal,
} from "reactstrap";
import bankIcon from "../images/payment-1.svg";
import flowIcon from "../images/payment-2.svg";

const items = [
  {
    name: "Equipo de maniobras tridimencionales",
    price: "$20.000",
    images: ["1", "2", "3"],
    flowUrl: "https://www.flow.cl/btn.php?token=grjo3i4",
  },
  {
    name: "Un kilt para Nico",
    price: "$30.000",
    images: ["4", "5", "6"],
    flowUrl: "https://www.flow.cl/btn.php?token=a5bgy8n",
  },
  {
    name: "R2D2 y C3P0",
    price: "$40.000",
    images: ["7", "8", "10"],
    flowUrl: "https://www.flow.cl/btn.php?token=apwaywn",
  },
  {
    name: "Una carta para ir a Howarts",
    price: "$60.000",
    images: ["11", "12", "13", "14"],
    flowUrl: "https://www.flow.cl/btn.php?token=68uof6w",
  },
  {
    name: "Regreso a la Calzada del Gigante",
    price: "$80.000",
    images: ["15", "16", "17"],
    flowUrl: "https://www.flow.cl/btn.php?token=yynurus",
  },
  {
    name: "El trono de hierro",
    price: "$100.000",
    images: ["18", "19"],
    flowUrl: "https://www.flow.cl/btn.php?token=qpv8qeq",
  },
  {
    name: "El anillo único",
    price: "$150.000",
    images: ["20", "21"],
    flowUrl: "https://www.flow.cl/btn.php?token=ygpdir0",
  },
  {
    name: "Un nuevo final de Game of Thrones",
    price: "$200.000",
    images: ["22"],
    flowUrl: "https://www.flow.cl/btn.php?token=ethofye",
  },
  {
    name: "Un viaje por La Tierra Media",
    price: "$300.000",
    images: ["23", "24", "25"],
    flowUrl: "https://www.flow.cl/btn.php?token=m67tsu9",
  },
];

function getImageUrl(imageName) {
  return `/gifts/${imageName}.png`;
}

export default function Gift() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedGift, setSelectedGift] = useState({});
  const toggleModal = () => setIsModalOpen((open) => !open);

  return (
    <main className="secondary-page">
      <h1 className="title text-center mb-3">¿Qué regalar?</h1>
      <p className="text-center fs-5">
        Como nos gusta ser un poco diferentes, no tenemos lista de novios, ni
        códigos con alguna tienda.
      </p>
      <p className="text-center fs-5">
        Por lo que te proponemos algunas alternativas si es que quieres y puedes
        darnos algún regalo en este gran día.
      </p>
      <div className="row gy-3 mt-4">
        {items.map((item) => (
          <div key={item.name} className="col-12 col-sm-6 col-lg-4">
            <Card className="h-100 gift-card overflow-hidden">
              {item.images.length === 1 ? (
                <img src={getImageUrl(item.images[0])} alt="" />
              ) : (
                <UncontrolledCarousel
                  ride={undefined}
                  interval={false}
                  items={item.images.map((imageName) => ({
                    src: getImageUrl(imageName),
                    key: imageName,
                  }))}
                />
              )}
              <CardBody className="text-center d-flex flex-column">
                <CardTitle tag="h5">{item.name}</CardTitle>
                <CardText>{item.price}</CardText>
                <Button
                  className="mt-auto"
                  color="primary"
                  onClick={() => {
                    setSelectedGift(item);
                    toggleModal();
                  }}
                >
                  Regalar
                </Button>
              </CardBody>
            </Card>
          </div>
        ))}
      </div>
      <blockquote>
        "Eres sangre de mi sangre y huesos de mis huesos. Te doy mi cuerpo para
        que los dos seamos uno. Te doy mi espíritu para que los dos seamos uno"
        - Jamie Fraser
      </blockquote>
      <Modal className="modal-md" isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Métodos de Pago</ModalHeader>
        <ModalBody>
          <h2 className="title fs-3 d-flex align-items-center">
            <img
              style={{ width: "3rem" }}
              className="me-2"
              src={bankIcon}
              alt=""
            />
            Transferencia Bancaria
          </h2>
          <div style={{ paddingLeft: "56px", paddingRight: "23px" }}>
            <p>Puedes transferirnos {selectedGift.price} a la cuenta:</p>
            <p className="mb-3">
              Eliana Andrea Quijada Germain
              <br />
              17.665.057-5
              <br />
              Banco Santander
              <br />
              Cuenta Corriente: 67629922
              <br />
              eliana.quijada.g@gmail.com
            </p>
          </div>
          <h2 className="title fs-3 d-flex align-items-center">
            <img
              style={{ width: "3rem" }}
              className="me-2"
              src={flowIcon}
              alt=""
            />
            Tarjeta de débito, crédito o prepago
          </h2>
          <p style={{ paddingLeft: "56px", paddingRight: "23px" }}>
            Puedes pagar con tarjetas utilizando Flow en el siguiente{" "}
            <a href={selectedGift.flowUrl} target="_blank" rel="noreferrer">
              link
            </a>
          </p>
        </ModalBody>
      </Modal>
    </main>
  );
}
